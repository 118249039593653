import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import {
  DEFAULT_PERMISSION_GROUPS,
  createDateRangeArray,
  createRxValue,
  createScheduleDayParser,
  createScheduleDayParserFromUser,
  distinctUntilChangedJson,
  fromRxValue,
  getActiveSchedule,
  hasChange,
  hasPermission,
} from '@app/_helpers/utils';
import { UtilService } from '@app/_services/util.service';
import { AppService } from '@app/app.service';
import {
  AuditFilterData,
  AuditFilterDefaultData,
} from '@app/components/audit-filter-popover/audit-filter-popover.component';
import { ReportsExportDialogComponent } from '@app/components/reports-export-dialog/reports-export-dialog.component';
import RoundingConfig from '@app/shared/dialogs/rounding-dialog/models/rounding-config';
import RoundingConfigData from '@app/shared/dialogs/rounding-dialog/models/rounding-config-data';
import { RoundingDialogComponent, RoundingTypes } from '@app/shared/dialogs/rounding-dialog/rounding-dialog.component';
import {
  nearestFutureMinutes,
  nearestMinutes,
  nearestPastMinutes,
} from '@app/shared/dialogs/rounding-dialog/rounding-functions';
import { ItemBillingFlag } from '@app/shared/filters/item-billing-flag';
import { ItemCompletedFlag } from '@app/shared/filters/item-completed-flag';
import {
  TIME_RANGE_CUSTOM_NAME,
  TIME_RANGE_DEFAULT_RANGES,
  TRange,
  getTimeRangeByKey,
} from '@app/shared/time-range-picker/time-range-constants';
import { ShellComponent } from '@app/shell/shell.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
  addHours,
  differenceInDays,
  isSameDay,
  isValid,
  isWithinInterval,
  startOfMinute,
  subHours,
} from 'date-fns/esm';
import { chain, sum } from 'lodash-es';
import { FormatPipe } from 'ngx-date-fns';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, share, startWith, switchMap } from 'rxjs/operators';
import {
  ApplicationSettingsQuery,
  ComegoQuery,
  ComegoService,
  ComegoTime,
  Logger,
  MyTimesQuery,
  MyTimesService,
  NotifyService,
  SearchService,
  Tag,
  TagType,
  TagsQuery,
  Time,
  TimesService,
  UserSchedule,
  UserSettings,
  UserSettingsQuery,
} from 'timeghost-api';

import { ComponentType } from '@angular/cdk/portal';
import {
  canViewDashboard,
  createUserPermissionFilter,
  hasPermissionByKey,
  isSupervisor,
  useAdmin,
} from '@app/_helpers/permission';
import { toPromise } from '@app/_helpers/promise';
import { useUserRulebreaksParser } from '@app/_helpers/rulebreaks/utils';
import { RXParser, createEncryptedRxValue, createRxValueWithCache } from '@app/_helpers/rx-value';
import { ScheduleExportDialogComponent } from '@app/components/schedule-export-dialog/schedule-export-dialog.component';
import { environment } from '@env/environment';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz/esm';
import { RoundingData } from 'timeghost-api/lib/services/times.service';
import { GroupType } from './data-table/data-table.component';
import { ReportsDownloadDialogComponent } from './reports-download-dialog/reports-download-dialog.component';

type DataArguments = [
  string[],
  string[],
  string[],
  string[],
  string[],
  Tag[],
  ItemCompletedFlag,
  TRange,
  { [key: string]: boolean },
];
@UntilDestroy()
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [FormatPipe],
})
export class ReportsComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();
  isScheduleExportable = false;
  readonly currentPage = createRxValue<string>(null);
  get isScheduleExportEnabled() {
    return this.currentPage.value?.split('.', 2)[0] === 'workinghours';
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  readonly showFilters = createRxValueWithCache<string[] | null>('reports.showFilters', null);
  readonly showFilters$ = this.showFilters.asObservable().pipe(
    map((x) => {
      const enabledKeys =
        !x || typeof x !== 'object'
          ? ['team', 'group', 'task', 'client', 'project', 'tag', 'billable', 'audit', 'amount']
          : x;
      return enabledKeys.reduce(
        (acc, r) => {
          acc[r] = true;
          return acc;
        },
        { enabled: !!enabledKeys?.length },
      );
    }),
  );
  readonly userIsAdmin = useAdmin();
  readonly amountToggle = createRxValueWithCache(
    'reports.amountViewable',
    !!this.appSettings.getValue().reports?.amount,
  );
  readonly canViewAmount = this.userSettingsQuery
    .select()
    .pipe(
      map(
        (x) =>
          x.workspace.permissionSettings.groupsCanSeeBillableRates.findIndex((p) => !!hasPermission(p.id, x)) !== -1,
      ),
    );
  readonly comegoOnly$ = this.appService.comegoOnly$;
  readonly comegoExportVisible$ = this.userSettingsQuery.select().pipe(
    map((user) => {
      return hasPermissionByKey(user, 'groupsCanComegoExportTimes');
    }),
  );
  readonly contingentVisible$ = this.userSettingsQuery.select().pipe(
    map((user) => {
      if (!user.workspace?.['timecontingent']?.['enabled']) return false;
      return (
        hasPermissionByKey(user, 'groupsCanContingentViewSelf') ||
        (user.workspace['timecontingent']?.['superviseCanViewSubordinates'] && isSupervisor(user))
      );
    }),
  );
  readonly visibleNav$ = combineLatest([this.userSettingsQuery.select(), this.contingentVisible$]).pipe(
    map(([user, contingent]) => {
      const permission = canViewDashboard(user);
      if (!permission) return null;
      const ccOnly = !!user?.workspace?.settings?.comegoOnly;
      const ccEnabled = !!user?.workspace?.settings?.comego;
      return {
        project: !ccOnly,
        workinghoursOnly: ccOnly,
        workinghours: ccEnabled,
        workinghoursExport: hasPermissionByKey(user, 'groupsCanComegoExportTimes'),
        contingent,
        showDivider: permission.workingHoursPermission && permission.projectPermission,
        permission,
      };
    }),
  );
  readonly groupFilter = createRxValueWithCache<string[]>(
    'reports.group',
    this.appSettings.getValue().reports?.groups || [],
  );
  readonly taskFilter = createRxValueWithCache<string[]>(
    'reports.task',
    this.appSettings.getValue().reports?.tasks || [],
  );
  readonly clientFilter = createRxValueWithCache<string[]>(
    'reports.client',
    this.appSettings.getValue().reports?.clients || [],
  );
  readonly tagFilter = createRxValueWithCache<Tag[]>(
    'reports.tag',
    ((tags) => {
      if (tags?.length > 0 && typeof tags[0] === 'string') return [];
      return tags;
    })(this.appSettings.getValue().reports?.tags || []),
  );
  readonly projectFilter = createRxValueWithCache<string[]>(
    'reports.project',
    this.appSettings.getValue().reports?.projects || [],
  );
  readonly auditFilter = createRxValueWithCache<AuditFilterData>(
    'reports.audit',
    this.appSettings.getValue().reports?.audit || AuditFilterDefaultData,
  );

  readonly auditFilter$active = combineLatest([this.auditFilter.asObservable(), this.currentPage.asObservable()]).pipe(
    map(([x, cp]) => x && (x.project || x.task || x.duration || (cp === 'workinghours' && x.rulebreaks))),
  );
  readonly teamFilter = createRxValueWithCache<string[]>(
    'reports.team',
    this.appSettings.getValue().reports?.team || [this.userSettingsQuery.getValue().id],
  );
  readonly billableFilter = createRxValueWithCache<ItemBillingFlag>(
    'reports.billable',
    this.appSettings.getValue().reports?.billable ?? ItemBillingFlag.BOTH,
  );
  readonly billableFilter$active = this.billableFilter.asObservable().pipe(map((x) => x !== ItemBillingFlag.BOTH));
  readonly completedFilter = createRxValueWithCache<ItemCompletedFlag>(
    'reports.completed',
    this.appSettings.getValue().reports?.completed ?? ItemCompletedFlag.BOTH,
  );
  readonly hasActiveFilter = combineLatest([
    this.teamFilter.asObservable(),
    this.groupFilter.asObservable(),
    this.taskFilter.asObservable(),
    this.clientFilter.asObservable(),
    this.tagFilter.asObservable(),
    this.projectFilter.asObservable(),
  ]).pipe(
    map((args: any[][]) => {
      return args.findIndex((x) => !!x?.length) !== -1;
    }),
  );
  resetFilter() {
    this.taskFilter.value =
      this.groupFilter.value =
      this.clientFilter.value =
      this.projectFilter.value =
      this.tagFilter.value =
        [];
    this.teamFilter.value = [this.userSettingsQuery.getValue().id];
    this.auditFilter.value = AuditFilterDefaultData;
    this.billableFilter.value = ItemBillingFlag.BOTH;
  }
  readonly activeSort = createRxValueWithCache<Sort>(
    'reports.sort',
    this.appSettings.getValue().reports?.detailedSort ?? {
      active: 'time',
      direction: 'desc',
    },
  );

  // this.reportsFilter.billableFilterChange.asObservable().pipe(
  //   startWith(this.reportsFilter.billableFilter),
  //   map(x => (this.reportsFilter.billableToggle === true ? x : ItemBillingFlag.BOTH)),
  //   distinctUntilChanged()
  // ),
  // this.group$,
  // this.reportsFilter.completedFilterChange.asObservable().pipe(
  //   startWith(this.reportsFilter.completedFilter),
  //   map(x => (this.reportsFilter.completedToggle === true ? x : ItemCompletedFlag.BOTH)),
  //   distinctUntilChanged()
  // ),
  // this.reportsFilter.clientFilterChange.asObservable().pipe(startWith([...this.reportsFilter.clientFilter])),
  // this.reportsFilter.teamFilterChange.asObservable().pipe(startWith([...this.reportsFilter.teamFilter])),
  // this.reportsFilter.tagFilterChange.asObservable().pipe(startWith([...this.reportsFilter.tagFilter])),
  // this.reportsFilter.projectFilterChange.asObservable().pipe(startWith([...this.reportsFilter.projectFilter])),

  private _timeLoading = new BehaviorSubject<boolean>(true);
  readonly timeLoading$ = this._timeLoading.asObservable().pipe(distinctUntilChanged());
  get timeLoading() {
    return this._timeLoading.getValue();
  }
  set timeLoading(val: boolean) {
    this._timeLoading.next(val);
  }

  private _timeData = new BehaviorSubject<any>(null);
  readonly timeData = this._timeData.asObservable().pipe(distinctUntilChanged(), untilDestroyed(this));
  private _pieData = new BehaviorSubject<any>(null);
  readonly pieData = this._pieData.asObservable().pipe(distinctUntilChanged(), untilDestroyed(this));
  readonly filterType: typeof FilterType = FilterType;
  readonly billType: typeof BillType = BillType;
  readonly billTypes = Object.keys(this.billType).map((x, i) => ({
    key: x,
    index: i,
    value: () => this.billType[x],
  }));
  readonly billControl = new UntypedFormControl(0);
  readonly billing = this.billControl.valueChanges.pipe(
    startWith(this.billControl.value),
    distinctUntilChanged(),
    map((x): BillType => this.billType[Object.keys(this.billType)[x]]),
  );
  constructor(
    private shell: ShellComponent,
    private dialog: MatDialog,
    private myTimesService: MyTimesService,
    private comegoService: ComegoService,
    private comegoQuery: ComegoQuery,
    private timesService: TimesService,
    private myTimeQuery: MyTimesQuery,
    private translate: TranslateService,
    private appService: AppService,
    private userSettingsQuery: UserSettingsQuery,
    private router: Router,
    private tagsQuery: TagsQuery,
    private route: ActivatedRoute,
    private appSettings: ApplicationSettingsQuery,
    private searchService: SearchService,
    private notifyService: NotifyService,
  ) {}
  get currencyCode() {
    return 'attach_money';
  }
  get isTeams() {
    return this.appService.isTeams();
  }
  private teamDataSource = this.myTimeQuery.selectAll();
  readonly teamDataSource$ = this.teamDataSource.pipe(distinctUntilChanged());
  summed(times: Time[]) {
    return UtilService.parseMS(sum(times.filter((y) => y.timeDiff > 0).map((y) => y.timeDiff)) * 1000, {
      showSeconds: false,
    });
  }
  summedBilled(times: Time[]) {
    return UtilService.parseMS(sum(times.filter((y) => !!y.billable && y.timeDiff > 0).map((y) => y.timeDiff)) * 1000, {
      showSeconds: false,
    });
  }
  summedNonBilled(times: Time[]) {
    return UtilService.parseMS(sum(times.filter((y) => !y.billable && y.timeDiff > 0).map((y) => y.timeDiff)) * 1000, {
      showSeconds: false,
    });
  }
  get locale() {
    return this.translate.currentLang;
  }
  toggleNav() {
    this.shell.sidenav.toggle();
  }
  get isMobile() {
    return this.shell.isMobile;
  }
  readonly range = createRxValueWithCache('reports.range', TIME_RANGE_DEFAULT_RANGES.week, {
    parser: RXParser.DateParser('from', 'to'),
  });
  readonly range$ = combineLatest([this.range.asObservable(), this.route.params.pipe(distinctUntilChangedJson())]).pipe(
    debounceTimeAfterFirst(100),
    distinctUntilChangedJson(),
    map(([range, q]) => {
      const from = ((date) => (isValid(date) ? date : null))(new Date(q.start)),
        to = ((date) => (isValid(date) ? date : null))(new Date(q.end));
      const newRange = Object.assign(range, from && to ? { from, to } : {});
      return newRange;
    }),
  );
  get startDate() {
    return this.range$.pipe(map((x) => x.from));
  }
  get endDate() {
    return this.range$.pipe(map((x) => x.to));
  }
  private _group = new BehaviorSubject<GroupType>(GroupType.Project);
  get group() {
    return this._group.getValue();
  }
  set group(val: GroupType) {
    this._group.next(val);
  }
  readonly group$ = this._group.asObservable().pipe(startWith(GroupType.Project), distinctUntilChanged());
  groupChange(ev: GroupType) {
    this.group = ev;
  }
  private team: boolean;
  setTeam(val: MatSlideToggleChange) {
    this._teamData.next(!!val.checked);
    this.team = val.checked;
  }
  private _teamData = new BehaviorSubject(false);
  readonly teamData = this._teamData.asObservable().pipe(startWith(), distinctUntilChanged());
  teamStream: Observable<Observable<Time[]>>;
  private _timeStatistics = new BehaviorSubject<IReportTimeStatistics>(null);
  readonly timeStatistics$ = this._timeStatistics.asObservable().pipe(distinctUntilChanged());
  get timeStatistics() {
    return this._timeStatistics.getValue();
  }
  set timeStatistics(val: IReportTimeStatistics) {
    this._timeStatistics.next(val);
  }
  readonly roundingData$ = this.appService.roundingData$;
  private roundFn(rounding: RoundingConfigData, times: number[]) {
    if (!rounding?.enabled) return times;
    if (rounding.type === RoundingTypes.Nearest) {
      times = times.map((c) => nearestMinutes(rounding.minutes, c) * 60);
    }
    if (rounding.type === RoundingTypes.UpTo) {
      times = times.map((c) => nearestFutureMinutes(rounding.minutes, c) * 60);
    }
    if (rounding.type === RoundingTypes.DownTo) {
      times = times.map((c) => nearestPastMinutes(rounding.minutes, c) * 60);
    }
    return times;
  }
  ngAfterViewInit() {
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .pipe(
        distinctUntilChangedJson(({ user, project }) => ({ user, project })),
        debounceTimeAfterFirst(100),
        switchMap(({ user, project, start, end }) => {
          let hasChange = false;
          log.debug('qparams', user, project);
          if (
            user &&
            (user === 'all'
              ? true
              : this.teamFilter.value && this.teamFilter.value.length !== 1 && this.teamFilter.value?.[0] !== user)
          ) {
            this.teamFilter.value =
              user === 'all'
                ? (this.userSettingsQuery.getValue()?.workspace?.users?.map((x) => x.id) ?? [user])
                : [user];
            hasChange = true;
          }
          if (project) {
            this.projectFilter.value = [project];
            hasChange = true;
          }
          if (hasChange || user || project) {
            return this.router
              .navigate([], {
                queryParams: {
                  user: undefined,
                  project: undefined,
                },
                queryParamsHandling: 'merge',
                state: {
                  start,
                  end,
                  project,
                },
                replaceUrl: true,
              })
              .catch((err) => {
                log.error(err);
                return {};
              });
          }
          return Promise.resolve();
        }),
      )
      .subscribe();
  }
  private _reload = new Subject<void>();
  reload() {
    this._reload.next();
  }
  readonly users$ = combineLatest([
    this.teamFilter.asObservable(),
    this.groupFilter.asObservable(),
    this.userSettingsQuery.select(),
  ]).pipe(
    map(([users, groups, user]) => {
      return groups.reduce(
        (acc, r) => {
          const grp = user.workspace.groups.find((x) => x.id === r);
          acc.push(
            ...grp.users
              .map((x) => x.id)
              .filter((u) => !acc.find((au) => au === u) && user.workspace.users.find((wuser) => wuser.id === u)),
          );
          return acc;
        },
        users || [user.id],
      );
    }),
  );
  private readonly data = createRxValue({ times: [], comego: [] } as {
    times: Time[];
    comego: Time[];
  });
  readonly data$ = this.data.asObservable();
  readonly data$shared = createRxValue<Time[]>([]);
  get sharedTimes() {
    return this.data$shared.value;
  }
  readonly rulebreakParser = useUserRulebreaksParser();
  readonly data$sharedFilter = createRxValue<RangeFilterData>(null);
  readonly data$filtered = fromRxValue(
    combineLatest([
      this.data$shared.asObservable(),
      this.billableFilter.asObservable(),
      this.auditFilter.asObservable(),
      this.userSettingsQuery.select(),
      this.showFilters$,
    ]).pipe(
      untilDestroyed(this),
      switchMap(
        async ([data, billable, audit, user, show]: [
          Time[],
          ItemBillingFlag,
          AuditFilterData,
          UserSettings,
          DataArguments[8],
        ]) => {
          let newData: Time[] = [...data];
          if (show.billable && billable !== ItemBillingFlag.BOTH) {
            newData = newData.filter((x) => {
              return billable === ItemBillingFlag.BILLED ? x.billable : !x.billable;
            });
          }
          if (show.audit && audit) {
            if (audit.project) newData = newData.filter((x) => x.project.useAsDefault);
            if (audit.task) newData = newData.filter((x) => !x.task);
            if (audit.duration && audit.modTime) {
              const isLess = audit.mod === 0,
                timeDiff = ((val) => (val < 0 ? val * -1 : val))(
                  (([hours, minutes]) => hours * 3600 + minutes * 60)(audit.modTime.split(':', 2).map(Number)),
                ),
                checkTimeDiff = (val: number) => (isLess ? timeDiff <= val : timeDiff >= val);
              newData = newData.filter((x) => checkTimeDiff(x.timeDiff));
            }
            const myTimezone = this.userSettingsQuery.getValue()?.settings?.timeZone;
            if (audit.timezone === 'adjust' && myTimezone) {
              const parseZonedTime = (dt: Date, fromTz?: string) =>
                utcToZonedTime(
                  zonedTimeToUtc(dt, fromTz ?? Intl.DateTimeFormat().resolvedOptions().timeZone).getTime(),
                  myTimezone,
                );
              newData = newData.map((x) => {
                const start = parseZonedTime(new Date(x.start), x.timeZone).toISOString(),
                  end = parseZonedTime(new Date(x.end), x.timeZone).toISOString();
                return { ...x, viewStart: start, viewEnd: end };
              });
            }
          }
          return newData;
        },
      ),
    ),
  );
  readonly data$project = this.data$filtered.asObservable();
  readonly data$all = fromRxValue(this.data$, { comego: [], times: [] });
  readonly data$comego = fromRxValue(this.data$.pipe(map((x) => x.comego)));

  readonly scheduleType$ = this.userSettingsQuery
    .select()
    .pipe(map((x) => (x.workspace.settings?.comego ? 'comego' : 'project')));
  readonly stats$ = combineLatest([this.data$project, this.userSettingsQuery.select(), this.range$]).pipe(
    map(([times, user, range]) => {
      const now = new Date();
      const sched = getActiveSchedule(user, range.from, false);
      if (!sched || !sched.enabled) return null;
      const isPastSchedule = now.getTime() > range.to.getTime();
      const dayParser = createScheduleDayParser(sched as any);
      if (!dayParser) return null;
      if (!times) times = [];
      const stats = createDateRangeArray(range.from, range.to, false)[0].reduce(
        (acc, [d]) => {
          const dayParser = createScheduleDayParserFromUser(user, d);
          const used = times.reduce((tcc, t) => {
            if (isSameDay(d, new Date(t.start))) return (tcc += t.timeDiff);
            return tcc;
          }, 0);
          if (!dayParser) {
            acc.used += used;
            return acc;
          }
          const data = dayParser(times, d);
          if (!data?.enabled) {
            acc.used += used;
            return acc;
          }
          if (!acc?.schedule) acc.schedule = data.schedule;
          acc.used += data.usage;
          acc.max += data.cap;
          return acc;
        },
        { max: 0, used: 0, schedule: null } as {
          used: number;
          max: number;
          schedule: UserSchedule;
        },
      );
      if (!stats) return null;
      const pastFailure = isPastSchedule && stats.used < stats.max;
      return {
        ...stats,
        total: {
          ...stats,
        },
        isPastSchedule,
        pastFailure,
        graphState: pastFailure ? 'warn' : stats.used < stats.max ? 'accent' : 'success',
      };
    }),
  );
  readonly exportDisabled$ = combineLatest([
    this.timeLoading$,
    this.data$,
    this.currentPage.asObservable(),
    this.userSettingsQuery.select(),
  ]).pipe(
    map(([loading, data, page, user]) => {
      if (loading) return true;
      const currentPage: 'project' | 'workinghours' = page?.split('.', 2)[0] as any;
      if (currentPage === 'workinghours' && !(user.workspace.settings.comego ? data?.comego : data?.times)?.length)
        return true;
      if (currentPage === 'project' && !data?.times?.length) return true;
      return false;
    }),
  );
  readonly data$tasks = fromRxValue(
    this.data$sharedFilter.asObservable().pipe(map((x) => x?.tasks?.filter((t) => !!t) || [])),
  );
  readonly data$projects = fromRxValue(
    this.data$sharedFilter.asObservable().pipe(map((x) => x?.projects?.filter((t) => !!t) || [])),
  );
  readonly data$tags = fromRxValue(
    this.data$sharedFilter.asObservable().pipe(
      map((x) =>
        [
          ...(x?.tags?.filter((t) => !!t) || []),
          ...(x?.projects.reduce((l, r) => {
            // @ts-ignore
            if (r?.tags?.length) l.push(...r.tags);
            return l;
          }, []) || []),
        ]
          .filter(Boolean)
          .uniqBy((x) => x.id),
      ),
    ),
  );
  readonly data$stats = combineLatest([this.data$project.pipe(share()), this.roundingData$]).pipe(
    this.roundingHandler(),
    map(([x]) => {
      return {
        summed: x.reduce((l, r) => (l += ~~r.timeDiff), 0),
        billed: x.filter((x) => x.billable).reduce((l, r) => (l += ~~r.timeDiff), 0),
        nonBilled: x.filter((x) => !x.billable).reduce((l, r) => (l += ~~r.timeDiff), 0),
        amount:
          x.filter((x) => x.amount !== undefined && x.amount !== null).length > 0
            ? {
                value: x.reduce((l, r) => (l += r.amount), 0),
              }
            : null,
      };
    }),
  );
  testFilter(time: Time) {
    const groups = this.groupFilter.value,
      _team = this.teamFilter.value,
      tasks = this.taskFilter.value,
      projects = this.projectFilter.value,
      clients = this.clientFilter.value,
      _tags = this.tagFilter.value;
    const user = this.userSettingsQuery.getValue();

    const teamFromGroups: string[] = groups
      .map((g) => user.workspace.groups.find((_g) => _g.id === g))
      .filter((g) => !!g)
      .reduce((l, r) => [...l, ...(r.users?.map((u) => u.id) ?? [])], [])
      .filter((u) => !!u)
      .uniq();
    const team = [..._team, ...teamFromGroups];
    const tags = _tags.reduce(
      (tagCollection, r) => {
        const type = r.tagType;
        if (type === TagType.Project) tagCollection.project.push(r);
        else if (type === TagType.Time) tagCollection.times.push(r);
        return tagCollection;
      },
      { project: [], times: [] },
    );
    if (team.length > 0 && !team.find((x) => x === time.user?.id)) return false;
    if (clients.length > 0 && !clients.find((x) => x === time.client?.id)) return false;
    if (projects.length > 0 && !projects.find((x) => x === time.project?.id)) return false;
    if (tasks.length > 0 && !tasks.find((x) => x === time.task?.id)) return false;
    if (tags.times.length > 0 && !tags.times.find((x) => !!time.tags?.find((y) => y.id === x))) return false;
    if (
      tags.project.length > 0 &&
      time.project?.tags &&
      !tags.project.find((x) => !!time.project?.tags?.find((y) => y.id === x))
    )
      return false;
    return true;
  }
  readonly lastVisitDashboard = createEncryptedRxValue<string>('lastVisitDashboard', '/dashboard', {
    addOperators: (s) =>
      s.pipe(
        filter((url) => url?.startsWith('/dashboard')),
        distinctUntilChanged(),
      ),
  });
  getComegoTimesFiltered(from: Date, to: Date, users: string[]) {
    const http = this.comegoService.adapter;
    return http
      .post(environment.serverUrl + '/get/comego', {
        $filter:
          `start ge '${subHours(from, 12).toISOString()}' and start le '${addHours(to, 12).toISOString()}'`.concat(
            (users?.length && ` and (${users.map((u) => `user__id eq '${u}'`).join(' or ')})`) || '',
          ),
      })
      .toPromise<ComegoTime[]>()
      .then((times) => times?.filter(({ start }) => isWithinInterval(new Date(start), { start: from, end: to })));
  }
  ngOnInit() {
    const user = this.userSettingsQuery.getValue();
    const qparams = this.route.snapshot.queryParams;
    if (qparams.user && user.workspace.users?.find((x) => x.id === qparams.user))
      this.teamFilter.value = [qparams.user];
    else if (this.teamFilter.value.length === 0 && this.groupFilter.value.length === 0)
      this.teamFilter.value = [user.id];
    this.router.events
      .pipe(
        filter((x) => {
          const isRouteEvent = x instanceof RouterEvent;
          if (!isRouteEvent) return false;
          return !!x.url?.startsWith('/dashboard');
        }),
        debounceTimeAfterFirst(10),
        untilDestroyed(this),
      )
      .subscribe((sourceEvent: any) => {
        const event = sourceEvent as RouterEvent;
        log.debug('url', event.url);
        const recentPath = new URL(event.url, location.origin).pathname;
        this.lastVisitDashboard.next(recentPath);
      });
    if (((x) => x?.start && x?.end)(qparams)) {
      const qStart = new Date(qparams.start),
        qEnd = new Date(qparams.end);
      if (isValid(qStart) && isValid(qEnd))
        this.range.update(() => {
          const rType = getTimeRangeByKey(qparams.rt);
          const newData = {
            from: qStart,
            to: qEnd,
            rangeType: rType?.rangeType || differenceInDays(qStart, qEnd) > 1 ? 'week' : 'day',
            name: TIME_RANGE_CUSTOM_NAME,
            ...(rType || {}),
          } as TRange;
          if (rType) rType.afterParse?.(newData);
          return newData;
        });
    }
    combineLatest([
      this.teamFilter.asObservable(),
      this.groupFilter.asObservable(),
      this.projectFilter.asObservable(),
      this.clientFilter.asObservable(),
      this.taskFilter.asObservable(),
      this.tagFilter.asObservable(),
      this.completedFilter.asObservable(),
      this.range$,
      this.showFilters$,
      this._reload.asObservable().pipe(
        startWith(Date.now()),
        map(() => Date.now()),
      ),
    ])
      .pipe(
        map((x): DataArguments => x as any),
        untilDestroyed(this),
        debounceTimeAfterFirst(100),
        distinctUntilChanged(),
        switchMap(async ([team, groups, projects, clients, tasks, tags, completed, range, show]) => {
          this.timeLoading = true;
          const user = this.userSettingsQuery.getValue();
          const teamFromGroups: string[] =
            groups
              ?.map((g) => user.workspace.groups.find((_g) => _g.id === g))
              .filter((g) => !!g)
              .reduce((l, r) => [...l, ...(r.users?.map((u) => u.id) ?? [])], [])
              .filter((u) => !!u)
              .uniq() || [];
          const _team = [...(team || []), ...teamFromGroups];
          const getTimes = (from: Date, to: Date) =>
            toPromise(
              this.timesService.getTeamTimesFilteredBetweenRange({
                from,
                to,
                userIdsFilter: _team,
                ...(show.project
                  ? {
                      projectIdsFilter: projects,
                    }
                  : {}),
                ...(show.client
                  ? {
                      clientIdsFilter: clients,
                    }
                  : {}),
                ...(show.task
                  ? {
                      taskIdsFilter: tasks,
                    }
                  : {}),
                ...(show.tag
                  ? {
                      ...(tags?.reduce(
                        (acc, tag) => {
                          if (tag.tagType === TagType.Project) acc.project_tagIdsFilter.push(tag.id);
                          else if (tag.tagType === TagType.Time) acc.tagIdsFilter.push(tag.id);
                          return acc;
                        },
                        { tagIdsFilter: [], project_tagIdsFilter: [] },
                      ) ?? {}),
                    }
                  : {}),
              }),
            );
          const userIsAdmin = hasPermission(DEFAULT_PERMISSION_GROUPS.Admin, user);
          const allUsersSelected = this.teamFilterAllSelected();
          const comegoTimes = await this.getComegoTimesFiltered(range.from, range.to, allUsersSelected ? [] : team)
            .then((x) => (allUsersSelected ? x : x.filter((c) => c.user?.id && !!team?.includes?.(c.user.id))))
            .then((x) =>
              x
                .map((t) => {
                  const start = new Date(t.start),
                    end = new Date(t.end || Date.now());
                  if (!end || !isValid(end)) return null;
                  return {
                    ...t,
                    recording: !t.end,
                    start,
                    end,
                    timeDiff: (startOfMinute(end).getTime() - startOfMinute(start).getTime()) / 1000,
                  } as any as Time;
                })
                .filter(Boolean),
            );
          const regularTimes = await getTimes(range.from, range.to)
            .then((x) =>
              completed === ItemCompletedFlag.BOTH
                ? x
                : x.filter((x) =>
                    completed === ItemCompletedFlag.COMPLETED ? x.project.completed : !x.project.completed,
                  ),
            )
            .finally(() => {
              this.timeLoading = false;
            });
          return {
            times: regularTimes,
            comego: comegoTimes,
          };
        }),
      )
      .subscribe((data) => this.data.update(data));
    this.data$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.data$shared.value = [...x.times.filter((y) => !!y.start && isValid(new Date(y.start)))];
    });
    this.range$
      .pipe(
        debounceTime(350),
        distinctUntilChangedJson(),
        map(({ from, to }) => ({ from, to })),
        switchMap((range) => this.searchService.getProjectsTasksAndTagsInTimeRange(range.from, range.to)),
      )
      .subscribe((fd) => {
        this.data$sharedFilter.value = fd;
      });

    this.notifyService.onMessage
      .asObservable()
      .pipe(
        untilDestroyed(this),
        filter((x) => {
          return x.payload?.cosmosEntityName === 'times' && (x.type === 1 ? this.testFilter(x.payload as Time) : true);
        }),
      )
      .subscribe(({ type, payload }: { type: number; payload: Time }) => {
        const idx = this.data$shared.value.findIndex((t) => t.id === payload.id);
        if (type === 1 && idx !== -1) type = 2;
        if (type === 2 && idx !== -1 && !this.testFilter(payload)) type = 3; // delete item locally if test failed
        if (type === 1 || type === 2) {
          const range = this.range.value;
          const start = new Date(payload.start);
          if (!isWithinInterval(start, { start: range.from, end: range.to })) {
            type = 3;
          }
        }
        if (type === 1) this.data$shared.update((state) => state.concat(payload), true);
        else if (type === 2 && idx !== -1) {
          this.data$shared.update((state) => {
            state[idx] = payload;
            return state;
          }, true);
        } else if (type === 3 && idx !== -1)
          this.data$shared.update((state) => state.filter((t) => t.id !== payload.id), true);
        log.debug('[notify] :: times', type, 'id: ', payload.id, 'idx: ', idx, payload);
      });
    combineLatest([
      this.currentPage.asObservable(),
      this.userSettingsQuery.select(),
      this.teamFilter.asObservable(),
    ]).subscribe(([currentPage, currentUser, users]) => {
      const userFilter = createUserPermissionFilter(currentUser, currentPage as any);
      const filteredUsers = userFilter(users.map((d) => ({ id: d })))?.map((d) => d.id);
      if (filteredUsers && hasChange(users, filteredUsers)) this.teamFilter.next(filteredUsers);
    });
  }
  teamFilterAllSelected() {
    return this.teamFilter.value.length === this.userSettingsQuery.getValue().workspace.users.length;
  }
  getRoundingOptions() {
    return this.appService.roundingData?.enabled === true
      ? {
          enabled: true,
          minutes: this.appService.roundingData.minutes,
          type:
            this.appService.roundingData.type === RoundingTypes.UpTo
              ? 'upto'
              : this.appService.roundingData.type === RoundingTypes.Nearest
                ? 'nearest'
                : this.appService.roundingData.type === RoundingTypes.DownTo
                  ? 'downto'
                  : null,
        }
      : {
          enabled: false,
        };
  }
  onRangeUpdate(r: TRange) {
    if (!isValid(new Date(r.from)) || !isValid(new Date(r.to))) return;
    this.range.value = r;
    r = this.range.value;
    this.router.navigate(this.route.snapshot.url, {
      queryParams: {
        start: r.from.toISOString(),
        end: r.to.toISOString(),
        rt: r.name || TIME_RANGE_CUSTOM_NAME,
      },
      queryParamsHandling: 'merge',
    });
  }
  getExportFilters() {
    const tags = this.tagFilter.value.reduce(
      (tagCollection, r) => {
        const type = r.tagType;
        if (type === TagType.Project) tagCollection.project.push(r.id);
        else if (type === TagType.Time) tagCollection.times.push(r.id);
        return tagCollection;
      },
      { project: [], times: [] },
    );
    let filters = {
      startDate: this.range.value?.from,
      endDate: this.range.value?.to,
      clients: this.clientFilter.value.length > 0 ? this.clientFilter.value : undefined,
      projects: this.projectFilter.value.length > 0 ? this.projectFilter.value : undefined,
      tags: tags.times.length > 0 ? tags.times : undefined,
      projectTags: tags.project.length > 0 ? tags.project : undefined,
      users: this.teamFilter.value.length > 0 ? this.teamFilter.value : undefined,
      groups: this.groupFilter.value.length > 0 ? this.groupFilter.value : undefined,
      tasks: this.taskFilter.value.length > 0 ? this.taskFilter.value : undefined,
      rounding: this.getRoundingOptions(),
      billable:
        this.billableFilter.value === ItemBillingFlag.BILLED
          ? 'billable'
          : this.billableFilter.value === ItemBillingFlag.NOT_BILLED
            ? 'not_billable'
            : this.billableFilter.value === ItemBillingFlag.BOTH
              ? 'both'
              : undefined,
      projectStatus:
        this.completedFilter.value === ItemCompletedFlag.COMPLETED
          ? 'completed'
          : this.completedFilter.value === ItemCompletedFlag.NOT_COMPLETED
            ? 'not_completed'
            : this.completedFilter.value === ItemCompletedFlag.BOTH
              ? 'both'
              : undefined,
    };
    if (filters.groups?.length > 0) {
      const user = this.userSettingsQuery.getValue();
      filters.users = [
        ...(filters.users || []),
        ...Object.keys(
          filters.groups.reduce(
            (users, groupId) => {
              const group = user.workspace.groups.find((x) => x.id === groupId);
              if (!group) return users;
              group.users.filter((x) => !(x.id in users)).forEach((user) => (users[user.id] = null));
              return users;
            },
            <{ [key: string]: any }>{},
          ),
        ),
      ].uniq();
    }
    return filters;
  }
  downloadExcel(options?: { groupedByProject?: boolean; timeZone?: string }) {
    const user = this.userSettingsQuery.getValue();
    const ref = this.openDownloadDialog(),
      filters = this.getExportFilters(),
      times = this.data$filtered.value,
      timeZone = options?.timeZone ? options?.timeZone : ('KEEP' as any),
      rounding = this.getRoundingOptions() as RoundingData,
      users = filters.users.filter((x) => times.find((t) => t.user?.id === x));
    (user.workspace.settings?.comego && this.isScheduleExportEnabled
      ? this.comegoService.downloadExcelReport({
          startDate: filters.startDate,
          endDate: filters.endDate,
          rounding,
          timeZone,
          dateRange: null,
          users,
        })
      : this.timesService.downloadExcelReport({
          ...(filters as any),
          groupedByProject: options?.groupedByProject === true,
          rounding,
          timeZone,
          users: filters.users.filter((x) => times.find((t) => t.user?.id === x)),
        })
    ).finally(() => {
      setTimeout(() => {
        ref.close();
      }, 1000);
    });
  }
  downloadCsv() {
    const user = this.userSettingsQuery.getValue();
    const ref = this.openDownloadDialog(),
      filters = this.getExportFilters(),
      rounding = this.getRoundingOptions() as RoundingData,
      users = filters.users;
    (user.workspace.settings?.comego && this.isScheduleExportEnabled
      ? this.comegoService.downloadCsvReport({
          startDate: filters.startDate,
          endDate: filters.endDate,
          rounding,
          dateRange: null,
          users,
        })
      : this.timesService.downloadCsvReport({
          ...(this.getExportFilters() as any),
          rounding: this.getRoundingOptions(),
        })
    ).finally(() => {
      setTimeout(() => {
        ref.close();
      }, 1000);
    });
  }
  openFlow() {
    const win = window.open(
      'https://emea.flow.microsoft.com/de-de/connectors/?filter=timeghost&category=all',
      '_blank',
    );
    win.focus();
  }

  openRoundingDialog() {
    this.dialog
      .open(RoundingDialogComponent, {
        data: new RoundingConfig({
          data: this.appService.roundingData,
        }),
      })
      .afterClosed()
      .pipe(
        switchMap(async (x) => {
          if (!!x) {
            await this.appService.updateRounding(x);
          }
          log.debug(x);
          return x;
        }),
      )
      .subscribe();
  }
  openExportDialog() {
    const filter = this.getExportFilters();
    const user = this.userSettingsQuery.getValue();
    const isSchedule = this.isScheduleExportEnabled;
    if (!isSchedule)
      filter.users =
        filter.users?.filter((u) => {
          return this.data$filtered.value.find((t) => t.user?.id === u);
        }) || []; // only allow users that have times in range
    return this.dialog.open(
      (isSchedule ? ScheduleExportDialogComponent : ReportsExportDialogComponent) as ComponentType<any>,
      {
        data: {
          filter,
          comego: isSchedule && !!user.workspace.settings?.comego,
        },
      },
    );
  }
  openDownloadDialog() {
    return this.dialog.open(ReportsDownloadDialogComponent, {
      disableClose: true,
    });
  }

  private _parseGrouped(x: Time[], grp: GroupType) {
    return chain(x)
      .groupBy((y: Time) => {
        // @ts-ignore
        if (grp === GroupType.Client && y.client) {
          // @ts-ignore
          return y.client.id;
        }
        if (grp === GroupType.Task && y.task) {
          return y.task.id;
        }
        if (grp === GroupType.User && y.user) {
          return y.user.id;
        }
        return y.project.id;
      })
      .toPairs()
      .value()
      .map(([key, value]: [string, Time[]]): [string, Time[]] => {
        value = value.filter((y) => !!y.task);
        return [key, value];
      });
  }
  roundingHandler(overrideEnabled?: boolean) {
    return map(([data, rounding, ...args]: [Time[], RoundingConfigData, ...any[]]) => {
      data =
        (!data && []) ||
        data.map((_y) => {
          const y: Time & { viewEnd?: string; viewStart?: string } = <Time>{
            ..._y,
          };
          if (overrideEnabled === undefined ? rounding?.enabled === true : overrideEnabled) {
            if (rounding.type === RoundingTypes.Nearest) {
              y.timeDiff = nearestMinutes(rounding.minutes, y.timeDiff * 1000) * 60;
            } else if (rounding.type === RoundingTypes.UpTo) {
              y.timeDiff = nearestFutureMinutes(rounding.minutes, y.timeDiff * 1000) * 60;
            } else if (rounding.type === RoundingTypes.DownTo) {
              y.timeDiff = nearestPastMinutes(rounding.minutes, y.timeDiff * 1000) * 60;
            }
            const st = new Date(Date.parse(y.start) + y.timeDiff * 1000);
            if (new Date(y.start) <= st) y.viewEnd = st.toISOString();
            if (y.timeDiff < 0) y.timeDiff = 0;
          }
          return y;
        });
      return [data, ...args] as [Time[], ...any[]];
    });
  }
}
const log = new Logger(ReportsComponent.name);

export enum FilterType {
  team = 'team',
  client = 'client',
  project = 'project',
  tag = 'tag',
}
export enum BillType {
  Both = 'Both',
  Billed = 'Billed',
  Non = 'Non Billed',
}
export interface IReportTimeStatistics {
  summed: string;
  nonBilled: string;
  billed: string;
  earnings: number;
}
type RangeFilterData = {
  projects: {
    name: string;
    id: string;
    completed: boolean;
    useAsDefault: boolean;
  }[];
  tasks: {
    name: string;
    id: string;
  }[];
  tags: Tag[];
};
