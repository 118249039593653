import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IdObject } from '@app/_classes/cosmos-entity';
import { hasPermissionByKey, isAdmin, isProjectManagerOfUser, isSupervisor } from '@app/_helpers/permission';
import { fromRxValue, userIsAwaitingActivation } from '@app/_helpers/utils';
import { ReportsComponent } from '@app/pages/reports-page/reports.component';
import { SatPopoverComponent as SatPopover, SatPopoverAnchoringService } from '@ncstate/sat-popover';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { xor } from 'lodash-es';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { firstBy } from 'thenby';
import { UserSettings, UserSettingsQuery } from 'timeghost-api';
@UntilDestroy()
@Component({
  selector: 'tg-employee-filter-popover',
  templateUrl: './employee-filter-popover.component.html',
  styleUrls: ['./employee-filter-popover.component.scss'],
  providers: [{ provide: SatPopoverAnchoringService, useExisting: false }],
})
export class EmployeeFilterPopoverComponent extends SatPopover implements OnInit {
  constructor(
    private userSettingsQuery: UserSettingsQuery,
    _focusTrapFactory: ConfigurableFocusTrapFactory,
    _anchoringService: SatPopoverAnchoringService,
    _viewContainerRef: ViewContainerRef,
    @Optional() @Inject(DOCUMENT) _document: any,
    private cdr: ChangeDetectorRef,
    private router: Router,
    @Optional() private reports?: ReportsComponent,
  ) {
    super(_focusTrapFactory, _anchoringService, _viewContainerRef, '200ms cubic-bezier(0.25, 0.8, 0.25, 1)', _document);
  }
  search = new UntypedFormControl('', [Validators.required, Validators.minLength(2)]);
  private _selectedItems = new BehaviorSubject<string[]>([]);
  readonly selectedItems$ = this._selectedItems.asObservable().pipe(distinctUntilChanged());

  get selectedItems() {
    return this._selectedItems.getValue();
  }
  @Input()
  set selectedItems(val: string[]) {
    const prev = this.selectedItems;
    this._selectedItems.next(val);

    if (xor(val, prev).length > 0) this.selectedItemsChange.emit(val);
  }
  @Input()
  set strictMode(value: boolean) {
    this._strictMode = value;
    this.strictModeSubject.next(value);
  }
  get strictMode() {
    return this._strictMode;
  }
  private _strictMode: boolean = false;
  private strictModeSubject = new BehaviorSubject<boolean>(false);

  @Output()
  selectedItemsChange = new EventEmitter(true);
  readonly items$filtered = fromRxValue(
    combineLatest([
      this.search.valueChanges.pipe(startWith(this.search.value), distinctUntilChanged()),
      this.userSettingsQuery.select(),
      this.reports?.currentPage?.asObservable() ?? of(null),
      this.selectedItems$,
      this.strictModeSubject.asObservable(),
    ]).pipe(
      map(([q, user, currentPage, selected, strictMode]: [string, UserSettings, string, string[], boolean]) => {
        const userIsAdmin = isAdmin(user);
        const userCanSupervise = isSupervisor(user);
        const userProjectManagerSupervisor = isProjectManagerOfUser(user);
        const comegoEnabled = user.workspace.settings?.comego ?? true;
        return (
          this.search.invalid
            ? user.workspace.users
            : user.workspace.users.filter((x) => {
                return x.name.toLowerCase().indexOf(q.toLowerCase()) !== -1;
              })
        )
          .map((x) => {
            return {
              ...x,
              selected: selected.findIndex((u) => u === x.id) !== -1,
              subordinate: !!user.workspace.users?.find(
                (d) => d.id === x.id && d['supervisors']?.find((u: IdObject) => u.id === user.id),
              ),
            };
          })
          .filter((d) => {
            if (currentPage === 'workinghours')
              return (
                d.id === user.id ||
                userIsAdmin ||
                (userCanSupervise && d.subordinate) ||
                hasPermissionByKey({ ...user, id: d.id } as any, 'groupsCanComegoViewTimes')
              );
            else return d.id === user.id || userIsAdmin || userProjectManagerSupervisor(d.id);
          })
          .filter((d) => {
            if (!this.strictMode) return true;
            if (d.removed || !d.has_license || userIsAwaitingActivation(d.id)) return false;
            return d.id === user.id || userIsAdmin || (userCanSupervise && d.subordinate);
          })
          .sort(firstBy('selected', 'desc').thenBy('subordinate', 'desc').thenBy('name', 'asc'));
      }),
    ),
  );

  @Output()
  itemsClose = new EventEmitter(true);
  ngOnInit(): void {
    this.openAnimationStartAtScale = this.closeAnimationEndAtScale = 0.9375;
    this.hasBackdrop = true;
    this.yAlign = 'below';
    this.xAlign = 'center';
    this.closed.asObservable().subscribe(() => this.search.reset(''));
    this.closed
      .asObservable()
      .pipe(
        untilDestroyed(this),
        map(() => this.selectedItems),
        distinctUntilChanged(),
      )
      .subscribe((val) => this.itemsClose.emit(val));
  }
  trackId(index: number, { id }: { id: string }) {
    return id;
  }
  selectItem(id: string) {
    if (this.selectedItems.findIndex((x) => x === id) !== -1)
      this.selectedItems = this.selectedItems.filter((x) => x !== id);
    else this.selectedItems = [...this.selectedItems, id];
  }
  selectAll() {
    this.selectedItems = this.items$filtered.value.map((employee) => employee.id).filter((id) => !!id);
  }
  selectSubordinates() {
    const user = this.userSettingsQuery.getValue();
    this.selectedItems = this.items$filtered.value
      .map((employee) => employee.id)
      .filter(
        (id) =>
          !!id &&
          user.workspace.users?.find((d) => d.id === id && d['supervisors']?.find((u: IdObject) => u.id === user.id)),
      );
  }

  selectNone() {
    this.selectedItems = [];
  }
  close() {
    super.close(this.selectedItems);
  }
}
