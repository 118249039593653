<div id="header" class="--report-header flex flex-col justify-stretch">
  <tg-toolbar tbclass="mat-toolbar-tg mat-toolbar-base h-initial" [showUser]="true" *ngIf="visibleNav$ | async as nav">
    <ng-template tgToolbarControls>
      <div class="flex flex-auto flex-row items-center justify-start gap-3">
        <div class="nav-container flex flex-row items-center justify-start" [class.nav-container-teams]="isTeams">
          @if (nav.permission.workingHoursPermission) {
            <a
              href="#"
              routerLink="/dashboard/schedules"
              routerLinkActive="active"
              queryParamsHandling="merge"
              class="nav-item dash-nav-item"
              >{{ 'reports.schedule' | translate }}</a
            >
          }
          @if (nav.permission.timecontingetPermission) {
            <a
              href="#"
              routerLink="/dashboard/contingent"
              routerLinkActive="active"
              queryParamsHandling="merge"
              class="nav-item dash-nav-item"
              >{{ 'reports.contingent' | translate }}</a
            >
          }
          @if (nav.showDivider) {
            <div class="mx-3">
              <mat-divider inset class="h-8 rotate-6" vertical></mat-divider>
            </div>
          }
          <a
            href="#"
            routerLink="/dashboard"
            routerLinkActive="active"
            queryParamsHandling="merge"
            *ngIf="nav.permission.projectPermission"
            [routerLinkActiveOptions]="{
              paths: 'exact',
              fragment: 'ignored',
              matrixParams: 'ignored',
              queryParams: 'ignored',
            }"
            class="nav-item dash-nav-item"
            >{{ 'reports.summary' | translate }}</a
          >
          <a
            href="#"
            routerLink="/dashboard/detailed"
            routerLinkActive="active"
            queryParamsHandling="merge"
            class="nav-item dash-nav-item"
            *ngIf="nav.permission.projectPermission"
            >{{ 'reports.detailed' | translate }}</a
          >
        </div>
        <div
          fxHide.lt-sm
          class="flex flex-auto flex-row items-center justify-start gap-2"
          *ngIf="nav.project || nav.workinghoursExport"
        >
          <div class="flex-auto"></div>
          <div class="flex flex-row items-center justify-start gap-1.5">
            <ng-container *ngTemplateOutlet="(timeLoading$ | async) ? loadingCircle : null"></ng-container>
            <button
              *ngIf="currentPage.value !== 'workinghours'"
              mat-icon-button
              (click)="this.openRoundingDialog()"
              fxlayout="column"
              [class.mat-accent]="(this.roundingData$ | async)?.enabled"
              [matTooltip]="'Rounding' | translate"
              class="items-center justify-center"
            >
              <mat-icon>history</mat-icon>
            </button>
            <button
              mat-icon-button
              fxlayout="column"
              [matTooltip]="'reports.export' | translate"
              [disabled]="exportDisabled$ | async"
              (click)="openExportDialog()"
              class="items-center justify-center"
            >
              <mat-icon>file_download</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template tgToolbarRightControls>
      <button mat-icon-button [matMenuTriggerFor]="moreSettingsMenu" fxHide.gt-sm>
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #moreSettingsMenu="matMenu">
        <button
          mat-menu-item
          (click)="this.openRoundingDialog()"
          [class.mat-accent]="(this.roundingData$ | async)?.enabled"
        >
          <mat-icon>history</mat-icon>
          <span translate>Rounding</span>
        </button>
        <button mat-menu-item (click)="openExportDialog()">
          <mat-icon>file_download</mat-icon>
          <span translate>reports.export</span>
        </button>
      </mat-menu>
    </ng-template>
  </tg-toolbar>
  <ng-container *ngIf="showFilters$ | async as enabledFilters">
    <mat-toolbar class="mat-toolbar-secondary mat-toolbar-mw scroll-auto" *ngIf="enabledFilters.enabled">
      <div class="flex flex-auto flex-row items-center justify-start gap-4 pb-2 pt-3">
        <div class="mat-hint mat-typography font-bold" fxHide.lt-sm>{{ 'utils.filter' | translate }}:</div>
        <div class="flex flex-auto flex-row items-center justify-start gap-2">
          <div class="flex flex-auto items-center space-x-2">
            <button
              class="mat-button-alt"
              [satPopoverAnchor]="employeeFilterPopover"
              (click)="employeeFilterPopover.toggle()"
              [class.active]="(teamFilter.value$ | async)?.length > 0"
              *ngIf="enabledFilters.team"
            >
              <div class="flex flex-row items-center justify-start gap-1">
                <mat-icon inline>supervisor_account</mat-icon>
                <span>{{ 'reports.filter-bar.users' | translate }}</span>
              </div>
              <ng-container *ngIf="teamFilter.value$ | async as c">
                <span class="filter-badge" *ngIf="c.length > 0">{{ c.length }}</span>
              </ng-container>
            </button>
            <button
              class="mat-button-alt"
              [satPopoverAnchor]="groupFilterPopover"
              (click)="groupFilterPopover.toggle()"
              [class.active]="(groupFilter.value$ | async)?.length > 0"
              *ngIf="enabledFilters.group && (userIsAdmin.value$ | async)"
            >
              <div class="flex flex-row items-center justify-start gap-1">
                <mat-icon inline>groups</mat-icon>
                <span>{{ 'reports.filter-bar.groups' | translate }}</span>
              </div>
              <ng-container *ngIf="groupFilter.value$ | async as c">
                <span class="filter-badge" *ngIf="c.length > 0">{{ c.length }}</span>
              </ng-container>
            </button>
            <button
              class="mat-button-alt"
              [satPopoverAnchor]="projectFilterPopover"
              (click)="projectFilterPopover.toggle()"
              [class.active]="(projectFilter.value$ | async)?.length > 0"
              *ngIf="enabledFilters.project"
            >
              <div class="flex flex-row items-center justify-start gap-1">
                <mat-icon inline>folder</mat-icon>
                <span>{{ 'reports.filter-bar.projects' | translate }}</span>
              </div>
              <ng-container *ngIf="projectFilter.value$ | async as c">
                <span class="filter-badge" *ngIf="c.length > 0">{{ c.length }}</span>
              </ng-container>
            </button>
            <button
              class="mat-button-alt"
              [satPopoverAnchor]="clientFilterPopover"
              (click)="clientFilterPopover.toggle()"
              [class.active]="(clientFilter.value$ | async)?.length > 0"
              *ngIf="enabledFilters.client"
            >
              <div class="flex flex-row items-center justify-start gap-1">
                <mat-icon inline>person</mat-icon>
                <span>{{ 'reports.filter-bar.clients' | translate }}</span>
              </div>
              <ng-container *ngIf="clientFilter.value$ | async as c">
                <span class="filter-badge" *ngIf="c.length > 0">{{ c.length }}</span>
              </ng-container>
            </button>
            <button
              class="mat-button-alt"
              [satPopoverAnchor]="taskFilterPopover"
              (click)="taskFilterPopover.toggle()"
              [class.active]="(taskFilter.value$ | async)?.length > 0"
              *ngIf="enabledFilters.task"
            >
              <div class="flex flex-row items-center justify-start gap-1">
                <mat-icon inline>check_circle</mat-icon>
                <span>{{ 'reports.filter-bar.tasks' | translate }}</span>
              </div>
              <ng-container *ngIf="taskFilter.value$ | async as c">
                <span class="filter-badge" *ngIf="c.length > 0">{{ c.length }}</span>
              </ng-container>
            </button>
            <button
              class="mat-button-alt"
              [satPopoverAnchor]="tagFilterPopover"
              (click)="tagFilterPopover.toggle()"
              [class.active]="(tagFilter.value$ | async)?.length > 0"
              *ngIf="enabledFilters.tag"
            >
              <div class="flex flex-row items-center justify-start gap-1">
                <mat-icon inline>label</mat-icon>
                <span>{{ 'reports.filter-bar.tags' | translate }}</span>
              </div>
              <ng-container *ngIf="tagFilter.value$ | async as c">
                <span class="filter-badge" *ngIf="c.length > 0">{{ c.length }}</span>
              </ng-container>
            </button>
            <button
              class="mat-button-alt"
              [class.active]="billableFilter$active | async"
              [matMenuTriggerFor]="billToggleMenu"
              #btm="matMenuTrigger"
              (click)="btm?.toggle()"
              *ngIf="enabledFilters.billable"
            >
              <div class="flex flex-row items-center justify-start gap-1">
                <mat-icon inline>attach_money</mat-icon>
                <span translate>filter-toolbar.billable-filter</span>
              </div>
            </button>
            <button
              class="mat-button-alt"
              [satPopoverAnchor]="auditFilterPopover"
              (click)="auditFilterPopover.toggle()"
              [class.active]="auditFilter$active | async"
              *ngIf="enabledFilters.audit"
            >
              <div class="flex flex-row items-center justify-start gap-1">
                <mat-icon inline>fact_check</mat-icon>
                <span>{{ 'reports.filter-bar.audit' | translate }}</span>
              </div>
              <ng-container *ngIf="auditFilter$active | async as c">
                <span class="filter-badge">1</span>
              </ng-container>
            </button>
            <button
              mat-icon-button
              *ngIf="(hasActiveFilter | async) && enabledFilters.enabled"
              (click)="resetFilter()"
              [matTooltip]="'utils.all-clear' | translate"
              class="!leading-normal"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
          <ng-container *ngIf="enabledFilters.amount">
            <mat-divider vertical class="h-8"></mat-divider>
            <div class="flex flex-row items-center space-x-1" *ngIf="canViewAmount | async">
              <div
                class="h-10 cursor-pointer select-none px-2 leading-10"
                [matTooltip]="'time.show-amount' | translate"
                [ngClass]="{ 'opacity-70': !amountToggle.value }"
                (click)="amountToggle.value = !amountToggle.value"
              >
                <mat-icon inline>{{ amountToggle.value ? 'attach_money' : 'money_off' }}</mat-icon>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-toolbar>
  </ng-container>
  <mat-menu #billToggleMenu="matMenu">
    <div class="m-2">
      <mat-radio-group [(ngModel)]="billableFilter.value">
        <div class="flex flex-col gap-1.5">
          <mat-radio-button [value]="0">
            {{ 'Dashboard_Both' | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="2">
            {{ 'dashboard.billed' | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="1">
            {{ 'dashboard.nonBilled' | translate }}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
  </mat-menu>
  <mat-menu #settingsMenu="matMenu" (click)="$event.stopPropagation()">
    <div class="mat-menu-item flex flex-row items-center justify-start gap-1.5">
      <mat-checkbox color="primary" [checked]="!!(this.teamData | async)" (change)="this.setTeam($event)">
        <div class="flex flex-row items-center justify-start gap-1.5">
          <mat-icon>people</mat-icon>
          <span>{{ 'utils.show-team' | translate }}</span>
        </div>
      </mat-checkbox>
    </div>
  </mat-menu>
  <mat-menu #downloadMenu="matMenu" (click)="$event.stopPropagation()" class="export-menu">
    <button mat-menu-item color="primary" [matMenuTriggerFor]="excelAdvancedMenu" [matMenuTriggerRestoreFocus]="false">
      <div class="flex flex-row items-center justify-start gap-2">
        <ms-icon style="font-size: 20px; height: 20px; width: 20px" [icon]="'ExcelDocument'"></ms-icon>
        <span>{{ 'utils.excel-download' | translate }}</span>
      </div>
    </button>
    <button mat-menu-item color="primary" (click)="this.downloadCsv()">
      <div class="flex flex-row items-center justify-start gap-2">
        <ms-icon style="font-size: 20px; height: 20px; width: 20px" [icon]="'AnalyticsReport'"></ms-icon>
        <span>{{ 'utils.csv-download' | translate }}</span>
      </div>
    </button>
    <button mat-menu-item color="primary" (click)="this.openFlow()" *ngIf="!(comegoOnly$ | async)">
      <div class="flex flex-row items-center justify-start gap-2">
        <ms-icon style="font-size: 20px; height: 20px; width: 20px" [icon]="'MicrosoftFlowLogo'"></ms-icon>
        <span>{{ 'utils.automatisation' | translate }}</span>
      </div>
    </button>
  </mat-menu>
</div>
<div class="container-wrapper">
  <div class="container-content">
    <router-outlet></router-outlet>
  </div>
</div>
<mat-menu #excelAdvancedMenu="matMenu" class="export-menu">
  <button mat-menu-item color="primary" (click)="this.downloadExcel({ groupedByProject: true, timeZone: 'ADJUST' })">
    <div class="flex flex-row items-center justify-start gap-2">
      <span>{{ 'utils.excel-download-grouped' | translate }}</span>
    </div>
  </button>
  <button mat-menu-item color="primary" (click)="this.downloadExcel({ groupedByProject: false, timeZone: 'ADJUST' })">
    <div class="flex flex-row items-center justify-start gap-2">
      <span>{{ 'utils.excel-download-simple' | translate }}</span>
    </div>
  </button>
  <button mat-menu-item color="primary" (click)="this.downloadExcel({ groupedByProject: true, timeZone: 'KEEP' })">
    <div class="flex flex-row items-center justify-start gap-2">
      <span>{{ 'utils.excel-download-grouped-keep' | translate }}</span>
    </div>
  </button>
  <button mat-menu-item color="primary" (click)="this.downloadExcel({ groupedByProject: false, timeZone: 'KEEP' })">
    <div class="flex flex-row items-center justify-start gap-2">
      <span>{{ 'utils.excel-download-simple-keep' | translate }}</span>
    </div>
  </button>
</mat-menu>
<ng-template #activeFiltersHoverCard let-filtersActivated="active">
  <div class="flex flex-row items-center justify-start gap-2">
    <span>Filter</span>
    <ng-container *ngIf="filtersActivated">
      <mat-divider vertical class="h-4"></mat-divider>
      <span>{{ filtersActivated }}</span>
    </ng-container>
  </div>
</ng-template>
<tg-project-filter-popover
  #projectFilterPopover
  [selectedItems]="projectFilter.value"
  (itemsClose)="projectFilter.value = $event"
></tg-project-filter-popover>
<tg-client-filter-popover
  #clientFilterPopover
  [selectedItems]="clientFilter.value"
  (itemsClose)="clientFilter.value = $event"
></tg-client-filter-popover>
<tg-employee-filter-popover
  #employeeFilterPopover
  [selectedItems]="teamFilter.value"
  (itemsClose)="teamFilter.value = $event"
></tg-employee-filter-popover>
<tg-task-filter-popover
  #taskFilterPopover
  [projects]="projectFilter.value"
  [selectedItems]="taskFilter.value"
  (itemsClose)="taskFilter.value = $event"
></tg-task-filter-popover>
<tg-tag-filter-popover
  #tagFilterPopover
  [selectedItems]="tagFilter.value"
  (itemsClose)="tagFilter.value = $event"
></tg-tag-filter-popover>
<tg-groups-filter-popover
  #groupFilterPopover
  [selectedItems]="groupFilter.value"
  (itemsClose)="groupFilter.value = $event"
></tg-groups-filter-popover>
<tg-audit-filter-popover
  #auditFilterPopover
  [(ngModel)]="auditFilter.value"
  [comegoTimes]="(currentPage.value$ | async) === 'workinghours'"
></tg-audit-filter-popover>
<ng-template #loadingCircle>
  <div class="flex flex-col items-center justify-center" style="width: 40px; height: 40px">
    <mat-progress-spinner [strokeWidth]="2" [diameter]="20" color="primary" [mode]="'indeterminate'">
    </mat-progress-spinner>
  </div>
</ng-template>
<ng-template #scheduleTypeTemplate let-type>
  <span>{{ 'schedules.dashboard.type-' + type | translate }}</span>
</ng-template>
